import React from "react";
import { graphql, Link } from "gatsby";
import Image from "gatsby-image";
import {
  Card,
  Row,
  Col,
  Container,
  CardGroup,
  CardDeck,
  CardColumns,
} from "react-bootstrap";
import Layout from "acciondigital-basic-theme/src/components/layout";
import Paginador from "acciondigital-basic-theme/src/components/paginador";
import SEO from "acciondigital-basic-theme/src/components/seo";
import PostCard from "acciondigital-basic-theme/src/components/PostCard";

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulProductos(
      sort: {order: [DESC,DESC], fields: [fields___OrdenCalc, updatedAt]}
      skip: $skip
      limit: $limit
    ) {
      nodes {
        titulo
        slug
        fechaPublicacion(formatString: "MMMM Do, YYYY")
        etiquetas
        imagenPrincipal {
          fixed(width: 200, height: 200) {
            ...GatsbyContentfulFixed
          }
        }
        descripcionCorta {
          descripcionCorta
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
/* 
img_thumbnail
localImage {
  childImageSharp {
    fixed(width: 250, height: 250) {
      ...GatsbyImageSharpFixed
    }
  }
}

    file(relativePath: { eq: "img_prod.jpg" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
 */

const CatalogoProductos = ({ data, pageContext }) => {
  const productos = data.allContentfulProductos.nodes;

  // console.log(productos);

  return (
    <Layout >
      <SEO title="Productos" description="Catálogo de productos." />
      <Row>
        <Col className="text-center pb-2">

          <Paginador
            basePath="/productos"
            previousPagePath={pageContext.previousPagePath}
            nextPagePath={pageContext.nextPagePath}
            numberOfPages={pageContext.numberOfPages}
            pageNumber={pageContext.pageNumber}
          />
        </Col>
      </Row>
      {productos.map((node) => (
        <div>
          <PostCard
            nombre={node.titulo}
            descripcion={node.descripcionCorta.descripcionCorta}
            slug={node.slug}
            imageSharpFixed={node.imagenPrincipal.fixed}
            textoBoton="Ver más."
            linkPrefix="/producto"
          />
        </div>
      ))}
      <Row>
        <Col className="text-center">
          <Paginador
            basePath="/productos"
            previousPagePath={pageContext.previousPagePath}
            nextPagePath={pageContext.nextPagePath}
            numberOfPages={pageContext.numberOfPages}
            pageNumber={pageContext.pageNumber}
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default CatalogoProductos;
